<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Custom Tunes</div>
        <div class="
            col-lg-7 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
            <button type="button" @click="customAddTuneModalOpen()" class="header-btn-outer btn btn-primary">
                Add Custom Tune
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="customTuneList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
            :rows="30" responsiveLayout="scroll" :lazy="true" :loading="loading" :rowHover="true"
            :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" dataKey="au1">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="tunecategory" header="Tune Category" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>
                        <span v-if="data.cf3 == 1">Welcome Tune</span>
                        <span v-if="data.cf3 == 2">Hold Tune</span>
                        <span v-if="data.cf3 == 3">Non Working Hours</span>
                        <span v-if="data.cf3 == 4">Missed Call Tune</span>
                        <span v-if="data.cf3 == ''">N/A</span>
                    </div>
                </template>
            </Column>
            <Column field="tunename" header="Tune Alias Name" headerStyle="width: 32%" bodyStyle="width: 32%">
                <template #body="{ data }">
                    <div class="text-capitalize">{{
                        data.cf6 ? data.cf6 : "N/A"
                    }}
                    </div>
                </template>
            </Column>
            <Column field="tunefile" header="Tune" headerStyle="width: 32%" bodyStyle="width: 32%">
                <template #body="{ data }">
                    <div v-if="data.cf4 != null && data.cf4 != ''"><audio controls controlsList="download">
                            <source :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/customtunes/' + data.cf4" type="audio/mpeg" />
                        </audio>
                    </div>
                    <div v-if="data.cf4 == null">-</div>
                </template>
            </Column>
            <Column field="status" header="Status" class="justify-content-center" headerStyle="width:8%"
                bodyStyle="width:8%">
                <template #body="{ data }">
                    <div>
                        <InputSwitch v-model="data.cf7" :true-value="dynamicTrueActiveValue"
                            :false-value="dynamicFalseInactiveValue" @change="confirmModalOpen(data)" />
                    </div>
                </template>
            </Column>
            <Column field="Action" header="Action" class="justify-content-center" headerStyle="width: 13%"
                bodyStyle="width: 13%">
                <template #body="{ data }">
                    <button type="button" class="btn custom-view-detail-btn" @click="customEditTuneModalOpen(data)">
                        Edit Details
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add Custom Tune modal stat here -->
    <div class="modal-mask" v-if="addCustomTuneDialog">
        <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Custom Tune</h5>
                    <button type="button" class="btn-close" @click="customAddTuneModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Tune Category<span class="text-danger">*</span></label>
                                <Multiselect v-model="add.tunecategory" :options="categoryList" :searchable="false"
                                    label="label" placeholder="Select" class="multiselect-custom" :canClear="true"
                                    :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                <div class="custom-error" v-if="v$.add.tunecategory.$error">
                                    {{ v$.add.tunecategory.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Upload Mp3<span class="text-danger">*</span></label>
                                <FileUpload mode="basic" ref="tunefileref" class="custom-upload-btn-ultima"
                                    v-model="add.tunefile" accept=".mp3" v-on:change="handleUploadAddCustomTune()" />
                                <div class="custom-error" v-if="errortuneaddfile">{{ errortuneaddfile }}</div>
                                <div class="custom-error" v-if="v$.add.tunefile.$error">
                                    {{ v$.add.tunefile.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="text-primary mb-2" v-if="exitaddpreviewurl != null">{{ exitaddpreviewurl.name }}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formAliasNameInput" class="form-label">Tune Alias Name<span
                                        class="text-danger">*</span></label>
                                <input type="text" v-model="add.aliasname" class="form-control text-capitalize"
                                    id="formAliasNameInput" placeholder="Enter Tune Alias Name" autocomplete="off"
                                    maxlength="50" />
                                <div class="custom-error" v-if="v$.add.aliasname.$error">
                                    {{ v$.add.aliasname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn user-role-btn text-center" @click="addCustomTune"
                            :disabled="addshowloader">
                            <span v-if="!addshowloader">Submit</span>
                            <div class="
                            spinner-border
                            text-light
                            custom-spinner-loader-btn
                          " role="status" v-if="addshowloader"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Custom Tune modal stat here -->
    <!-- Edit Custom Tune modal stat here -->
    <div class="modal-mask" v-if="editCustomTuneDialog">
        <div class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit Custom Tune</h5>
                    <button type="button" class="btn-close" @click="customEditTuneModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-body-fixed-height">
                        <div v-if="!showmodalloader">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Tune Category<span class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.tunecategory" :options="categoryList" :searchable="false"
                                            label="label" placeholder="Select" class="multiselect-custom" :canClear="true"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.tunecategory.$error">
                                            {{ v$.edit.tunecategory.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Mp3<span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept=".mp3" ref="tunefileref" class="custom-upload-btn-ultima"
                                            v-model="edit.tunefile" v-on:change="handleUploadEditCustomTune()" />
                                        <div class="custom-error" v-if="errortuneeditfile">{{ errortuneeditfile }}</div>
                                        <div class="custom-error" v-if="v$.edit.tunefile.$error">
                                            {{ v$.edit.tunefile.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="edit.tunefilesrc != null" class="mb-2">
                                    <audio controls controlsList="download">
                                        <source :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/customtunes/' + edit.tunefilesrc" type="audio/mpeg" />
                                    </audio>
                                </div>
                                <div class="text-primary mb-2" v-if="exiteditpreviewurl != null">{{ exiteditpreviewurl.name
                                }}</div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Tune Alias Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="edit.aliasname" class="form-control text-capitalize"
                                            id="formLastNameInput" placeholder="Enter Tune Alias Name" autocomplete="off"
                                            maxlength="50" />
                                        <div class="custom-error" v-if="v$.edit.aliasname.$error">
                                            {{ v$.edit.aliasname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showmodalloader">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn user-role-btn text-center" @click="updateCustomTune"
                            :disabled="showloader">
                            <span v-if="!showloader">Update</span>
                            <div class="
                            spinner-border
                            text-light
                            custom-spinner-loader-btn
                          " role="status" v-if="showloader"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit Custom Tune modal stat here -->
    <!-- confirmation modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn" @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="changeTuneStatus()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation modal end here -->
</template>
    
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            loading: false,
            customTuneList: [],
            editCustomTuneDialog: false,
            showloader: false,
            errormsg: "",
            tuneid: "",
            showmodalloader: false,
            totalRecords: 0,
            page_no: 0,
            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            categoryList: [
                { value: "1", label: "Welcome Tune" },
                { value: "2", label: "Hold Tune" },
                { value: "3", label: "Non Working Hours" },
                { value: "4", label: "Missed Call Tune" },
            ],
            errortuneeditfile: '',
            exiteditpreviewurl: null,
            edit: {
                tunecategory: '',
                aliasname: '',
                tunefile: null,
                tunefilesrc: '',
            },
            add: {
                tunecategory: '',
                aliasname: '',
                tunefile: null,
            },
            addshowloader: false,
            errortuneaddfile: '',
            exitaddpreviewurl: null,
            addCustomTuneDialog: false,
            confirm_popup_status: false,
            showconfirmloaderbtn: false,
            confirm_tune_id: '',
            confirm_status_value: '',
            client_info: '',
            voterstorageimgpath: '',
        };
    },
    validations() {
        return {
            edit: {
                tunecategory: {
                    required: helpers.withMessage("Please select tune category", required),
                },
                tunefile: helpers.withMessage(
                    "Please select custom tune",
                    requiredIf(
                        this.edit.tunefilesrc != '' && this.edit.tunefilesrc != null
                    )
                ),
                aliasname: { required: helpers.withMessage("Please enter alias name", required) },
            },
            add: {
                tunecategory: {
                    required: helpers.withMessage("Please select tune category", required),
                },
                tunefile: { required: helpers.withMessage("Please select custom tune", required) },
                aliasname: { required: helpers.withMessage("Please enter alias name", required) },
            },
        };
    },

    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getcttuneslist();
        this.client_info = JSON.parse(localStorage.client_info);
        this.voterstorageimgpath = this.client_info.maa24;
    },
    methods: {
        getcttuneslist(item) {
            this.loading = true;
            this.ApiService.getcttuneslist(item).then((data) => {
                if (data.status == 200) {
                    this.customTuneList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.customTuneList = '';
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcttuneslist({ page_no: this.page_no });
        },
        customEditTuneModalOpen(data) {
            this.editCustomTuneDialog = true;
            this.showmodalloader = true;
            this.edit.tunecategory = '';
            this.edit.tunefile = null;
            this.edit.aliasname = '';
            this.errortuneeditfile = '';
            this.exiteditpreviewurl = null;
            this.tuneid = data.cf1;
            if (data.cf3 == 1) {
                this.edit.tunecategory = {
                    label: "Welcome Tune",
                    value: data.cf3,
                }
            } else if (data.cf3 == 2) {
                this.edit.tunecategory = {
                    label: "Hold Tune",
                    value: data.cf3,
                }
            } else if (data.cf3 == 3) {
                this.edit.tunecategory = {
                    label: "Non Working Hours",
                    value: data.cf3,
                }
            } else if (data.cf3 == 4) {
                this.edit.tunecategory = {
                    label: "Missed Call Tune",
                    value: data.cf3,
                }
            }
            if (data.cf4 != '') {
                this.edit.tunefilesrc = data.cf4;
            }
            this.edit.aliasname = data.cf6;
            this.showmodalloader = false;
        },
        customEditTuneModalClose() {
            this.editCustomTuneDialog = false;
            this.edit.tunecategory = '';
            this.edit.tunefile = null;
            this.edit.aliasname = '';
            this.errortuneeditfile = '';
            this.exiteditpreviewurl = null;
        },
        handleUploadEditCustomTune() {
            var file = this.$refs.tunefileref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            if (!file.size > 2048) {
                this.errortuneeditfile = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errortuneeditfile = 'Invalid file type';
            } else {
                this.errortuneeditfile = '';
                this.edit.tunefile = this.$refs.tunefileref.files[0];
                this.exiteditpreviewurl = this.edit.tunefile;
            }
        },
        customAddTuneModalOpen() {
            this.add.tunecategory = '';
            this.add.tunefile = null;
            this.add.aliasname = '';
            this.errortuneaddfile = '';
            this.exitaddpreviewurl = null;
            this.addCustomTuneDialog = true;
        },
        customAddTuneModalClose() {
            this.addCustomTuneDialog = false;
            this.tunecategory = '';
            this.tunefile = null;
            this.aliasname = '';
            this.errortuneaddfile = '';
            this.exitaddpreviewurl = null;
        },
        handleUploadAddCustomTune() {
            var file = this.$refs.tunefileref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            if (!file.size > 2048) {
                this.errortuneaddfile = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errortuneaddfile = 'Invalid file type';
            } else {
                this.errortuneaddfile = '';
                this.add.tunefile = this.$refs.tunefileref.files[0];
                this.exitaddpreviewurl = this.add.tunefile;
            }
        },
        addCustomTune() {
            this.errormsg = '';
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append("cf3", this.add.tunecategory.value);
            formData.append('cf6', this.add.aliasname);
            if (this.add.tunefile != null) {
                formData.append("cf4", this.add.tunefile);
            }
            if (!this.v$.add.$error && !this.errortuneaddfile) {
                this.addshowloader = true;
                this.ApiService.addcustomtunes(formData).then((data) => {
                    if (data.success == true) {
                        this.addshowloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.addCustomTuneDialog = false;
                        this.add.tunecategory = '';
                        this.add.tunefile = null;
                        this.add.aliasname = '';
                        this.errortuneaddfile = '';
                        this.exitaddpreviewurl = null;
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getcttuneslist();
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.addshowloader = false;
                    }
                });
            }
        },
        updateCustomTune() {
            this.errormsg = '';
            this.v$.edit.$validate();
            var formData = new FormData();
            formData.append('cf1', this.tuneid);
            formData.append("cf3", this.edit.tunecategory.value);
            formData.append('cf6', this.edit.aliasname);
            if (this.edit.tunefile != null) {
                formData.append("cf4", this.edit.tunefile);
            }
            if (!this.v$.edit.$error && !this.errortuneeditfile) {
                this.showmodalloader = true;
                this.showloader = true;
                this.ApiService.updatecustomtunes(formData).then((data) => {
                    if (data.success == true) {
                        this.showloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.editCustomTuneDialog = false;
                        this.edit.tunecategory = '';
                        this.edit.tunefile = null;
                        this.edit.aliasname = '';
                        this.errortuneeditfile = '';
                        this.exiteditpreviewurl = null;
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getcttuneslist();
                        this.showmodalloader = false;
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.showloader = false;
                        this.showmodalloader = false;
                    }
                });
            }
        },
        confirmModalOpen(e) {
            this.confirm_tune_id = e.cf1;
            this.confirm_status_value = e.cf7;
            this.confirm_popup_status = true;
        },

        confirmModalClose() {
            this.confirm_popup_status = false;
            this.confirm_tune_id = '';
            this.confirm_status_value = '';
            this.getcttuneslist();
        },
        changeTuneStatus() {
            let fields = {};
            fields["cf1"] = this.confirm_tune_id;
            if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
                fields["cf7"] = 0;
            }
            if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
                fields["cf7"] = 1;
            }
            this.showconfirmloaderbtn = true;
            this.ApiService.cttunesStatusUpdate(fields).then((items) => {
                if (items.status === 200) {
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showconfirmloaderbtn = false;
                    this.getcttuneslist();
                    this.confirm_tune_id = '';
                    this.confirm_status_value = '';
                    this.confirm_popup_status = false;
                } else {
                    this.showconfirmloaderbtn = false;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
    },
};
</script>
    
<style scoped>
.btn.user-role-btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    margin-right: 12px;
    min-width: 80px;
}

.user-role-btn:focus {
    box-shadow: none;
}

.modal-body-fixed-height {
    min-height: 295px;
}

</style>
    
    